const $ = require('jquery/dist/jquery');

/**
 * AdminLTE & plugins
 */
require('admin-lte/plugins/jquery/jquery.min');
require('admin-lte/plugins/jquery-ui/jquery-ui.min');
require('admin-lte/plugins/bootstrap/js/bootstrap.bundle.min');
require('admin-lte/plugins/chart.js/Chart.min');
require('admin-lte/plugins/sparklines/sparkline');
require('admin-lte/plugins/jqvmap/jquery.vmap.min');
require('admin-lte/plugins/jqvmap/maps/jquery.vmap.usa');
require('admin-lte/plugins/jquery-knob/jquery.knob.min');
require('admin-lte/plugins/moment/moment-with-locales');
// require('admin-lte/plugins/moment/locales');
require('admin-lte/plugins/daterangepicker/daterangepicker');
// require('admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4');
require('admin-lte/plugins/summernote/summernote-bs4.min');
require('admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min');
require('admin-lte/dist/js/adminlte.min');

/**
 * other vendors
 */
require('icheck/icheck');
require('select2/dist/js/select2');

/**
 *
 */
// create global $ and jQuery variables
global.$ = global.jQuery = $;